import { useState, useEffect } from "react";
import styled from 'styled-components'


import Token from './Token';



import img4 from './assets/float.gif';





//import LogoText from 
const Section = styled.section`

 background-color: #473266;
 display: flex;
height: 100vh;

`
const HalfContainer = styled.div`
width: 100vw;
display: flex;
justify-content: center;
font-family: "Arial Black", Arial;
text-align: center;
color: #FFFFFF;
align-items: center;
font-size: 1.5em;
@media (min-width: 600px) {
  font-size: 2.5em;
}
     
  img{
 
width: 300px;
@media (min-width: 600px) {
  font-size: 1.5em;
    width: 600px;
     
  }
      
`



const Container2 = styled.div`
width: 100vw;
display: flex;
justify-content: center;
align-items: center;

`

const Navigation = () => {

  

  const [_accountcon, setAccountCon] =useState("");
  
    const [start, setStart] = useState(false);
    const [mycitizen, setCitizen] = useState(true);

    const [loot, setLoot] = useState(true);
    const [location, setLocation] = useState(true);

   
      const enterCitizen = async () => {
        setStart(true);
        setCitizen(false);
 

        setLoot(true);
        setLocation(true);
     
      
        
       
      }
     

  







const walletConnect = async () => {
    
  if(typeof window.ethereum !== "undefined"){
  const accounts = await window.ethereum.request({
  method: "eth_requestAccounts",
  });
    setAccountCon(accounts[0]);
   // getData(accounts[0]);
    
  }
  else {
    console.log("???");
  }

  }



       
     
  





  
   
      /*        {data.map(item => {
return (<option value={item.token_id}>Citizen #{item.token_id}</option>)
})}*/


useEffect(() => {

  console.log(_accountcon);
  if (_accountcon > 0){

  walletConnect();
}
// eslint-disable-next-line 
}, [_accountcon]);




//*header för gamla spelet* <Citstats><Citizenstats _XP={totales} _HP={totales1} _Energy={totales2} _name={totales4} _link={linkstring} _Loot={totales3} _BA={totales11} _BL={totales6} _BD={totales5} _BonusA={totales12} _BonusD={totales12} _BonusL={totales12}></Citizenstats></Citstats>
/*
<Container3><Subcontainer2><a href="https://twitter.com/allinoneout" target="_blank" rel="noreferrer">
         <img src={img1} width="30px" alt=""></img></a></Subcontainer2><Subcontainer2><a href="https://t.me/+Ps2rG37khKkyNjI8" target="_blank" rel="noreferrer"><img src={img2} width="40px" alt=""></img></a></Subcontainer2><Subcontainer2><a href="https://opensea.io/collection/wildoats" target="_blank" rel="noreferrer"><img src={img3} width="35px" alt=""></img></a></Subcontainer2> </Container3>
<Menu>
  <MenuItem onClick={enterCitizen}><img src={img5} width="75px" alt=""></img></MenuItem>
  <MenuItem onClick={enterLocation}><img src={img6} width="75px" alt=""></img></MenuItem>  
  <Container4> <Subcontainer><a href="https://twitter.com/allinoneout" target="_blank" rel="noreferrer"><img src={img1} width="30px" alt=""></img></a></Subcontainer><Subcontainer><a href="https://t.me/+Ps2rG37khKkyNjI8" target="_blank" rel="noreferrer"><img src={img2} width="35px" alt=""></img></a></Subcontainer><Subcontainer><a href="https://twitter.com/allinoneout" target="_blank" rel="noreferrer"><img src={img3} width="35px" alt=""></img> </a></Subcontainer></Container4>
  </Menu>
*/

  return (
    <Section>
  
     
        
      <Container2>

  <div className={`secti ${start ? "hiddenav" : ""}`}><HalfContainer onClick={enterCitizen}><img src={img4} alt=""></img></HalfContainer><HalfContainer><br></br>Do it! Click the box!</HalfContainer></div>

</Container2>

            <div className={`secti ${start ? "hiddenav" : ""}`}></div>
      <div className={`secti ${mycitizen ? "hiddenav" : ""}`}><Token></Token></div>
     
      </Section>
      
  )
}

export default Navigation