import React from 'react'
import styled from 'styled-components'

import img8 from './assets/x.png';
import img9 from './assets/tele.png';
import img10 from './assets/dex.png';
import img18 from './assets/opensea.png';
import img11 from './assets/forthec.png';
import img12 from './assets/memeloot2.png';
import img13 from './assets/basewhite.png';
import img14 from './assets/trepepe.png';
import img15 from './assets/PepeDicaprio.png';
import img2 from './assets/peng_low.png';
import img3 from './assets/pepememalow.png';
import img4 from './assets/pepemonitor.png';
import img5 from './assets/kermitlow.png';
import img6 from './assets/kista.png';
import img16 from './assets/mintandstake.png';
import img17 from './assets/tokenomiks.png';


const Section = styled.div`
width: 100vw;
color: #FFFFFF;
flex-wrap: wrap;
display: flex;

background-color: #473266;
justify-content: center;
align-items: top;
`
const Subcontainer2 = styled.div`
margin: 10px;
margin-top: 0px;

`
const ContainerTop = styled.div`
width: 100vw;
justify-content: center;
align-items: center;
font-family: "Arial Black", Arial;
text-align: center;
color: #FFFFFF;
font-size: 2.5em;
margin-bottom: 0px;

img{
 
  width: 270px;
}
  @media (min-width: 700px) {
   
    img{
 
      width: 490px;
    }
       
    }
      
`

const Container = styled.div`
width: 300px;
margin: 0px;
margin-top: 0px;
margin-bottom: 0px;
justify-content: center;
font-family: "Arial", Arial;
align-items: center;
text-align: center;
@media (min-width: 700px) {
   
  margin: 20px;
     
  }

  
      
`
const Inbet = styled.div`
font-family: "Arial", Arial;
text-align: center;
color: #ffffff;
justify-content: center;
align-items: center;
margin-bottom: 20px;

`
const Subtext1 = styled.div`
font-family: "Arial", Arial;
text-align: center;
font-size: 1.2em;
color: #ffffff;
justify-content: center;
align-items: center;
`

const TextContainer = styled.div`
width: 300px;
overflow-wrap: break-word;
background-color: #42498d;

border-radius: 30px;
margin-bottom: 20px;
padding: 20px;
font-family: "Arial Black", Arial;
text-align: center;
font-size: 1em;
color: #ffffff;
justify-content: center;
align-items: center;
`
const TextContainer7 = styled.div`
width: 300px;
overflow-wrap: break-word;
background-color: #42498d;
display: flex;
border-radius: 30px;
margin-bottom: 20px;
padding: 20px;
font-family: "Arial Black", Arial;
text-align: center;
font-size: 1em;
color: #ffffff;
justify-content: center;
align-items: center;
`
const TextContainer4 = styled.div`
width: 300px;
padding: 20px;
background-color: #42498d;
border-radius: 30px;
margin-bottom: 20px;
font-family: "ARIAL BLACK", Arial;
text-align: center;
font-size: 1em;
color: #ffffff;
justify-content: center;
align-items: center;
`


const HalfContainer = styled.div`
width: 300px;
height: 220px;
background-color: #42498d;
border-radius: 30px;
margin-bottom: 20px;
font-family: "Trebuchet MS", Arial;
text-align: center;
font-size: 1em;
color: #ffffff;
justify-content: center;
align-items: center;

`
const HalfContainer2 = styled.div`
width: 300px;
height: 270px;
background-color: #FFFFFF;
border-radius: 30px;
margin-bottom: 20px;
font-family: "Trebuchet MS", Arial;
text-align: center;
font-size: 1em;
color: #ffffff;
justify-content: center;
align-items: center;
background-image: url(${img5});

  background-size: 100%;
`
const HalfContainer5 = styled.div`
width: 300px;
height: 270px;
background-color: #FFFFFF;
border-radius: 30px;
margin-bottom: 20px;
font-family: "Trebuchet MS", Arial;
text-align: center;
font-size: 1em;
color: #ffffff;
justify-content: center;
align-items: center;
background-image: url(${img14});

  background-size: 100%;
`

const HalfContainer3 = styled.div`
width: 300px;
height: 200px;
background-color: #FFFFFF;
border-radius: 30px;
margin-bottom: 20px;
font-family: "Trebuchet MS", Arial;
text-align: center;
font-size: 1em;
color: #ffffff;
justify-content: center;
align-items: center;
background-image: url(${img3});

  background-size: 100%;
`
const HalfContainer8 = styled.div`
width: 300px;
height: 180px;
background-color: #FFFFFF;
border-radius: 30px;
margin-bottom: 20px;
font-family: "Trebuchet MS", Arial;
text-align: center;
font-size: 1em;
color: #ffffff;
justify-content: center;
align-items: center;
background-image: url(${img4});

  background-size: 100%;
`

const Round = styled.div`
width: 300px;
height: 300px;
margin-bottom: 20px;
padding-top: 45px;
background-color: #42498d;
border-radius: 200px;
font-family: "Trebuchet MS", Arial;
text-align: center;
font-size: 1em;
color: #000000;
justify-content: center;
align-items: center;
background-image: url(${img15});

  background-size: 120%;
`

const Round2 = styled.div`
width: 300px;
height: 300px;
margin-bottom: 20px;
padding-top: 0px;
background-color: #42498d;
border-radius: 200px;
font-family: "Trebuchet MS", Arial;
text-align: center;
font-size: 1em;
color: #000000;
justify-content: center;
align-items: center;
img{
 

  width: 300px;
     
  }
`

const Token = () => {

 
  return(
    <Section>
      <ContainerTop>  <img src={img12} alt=""></img></ContainerTop>
<Container>
<TextContainer4>

  <img src={img11} width="190px" alt=""></img>
  </TextContainer4>
  <HalfContainer2>
</HalfContainer2>
<Inbet>In to Solana? Not my problem.</Inbet>
<TextContainer7>


<Subcontainer2><a href="https://twitter.com/memelootonbase" target="_blank" rel="noreferrer">
         <img src={img8} width="40px" alt=""></img></a></Subcontainer2><Subcontainer2><a href="https://t.me/+Ps2rG37khKkyNjI8" target="_blank" rel="noreferrer"><img src={img9} width="50px" alt=""></img></a></Subcontainer2><Subcontainer2><a href="" target="_blank" rel="noreferrer"><img src={img10} width="45px" alt=""></img></a></Subcontainer2><Subcontainer2><a href="https://opensea.io/" target="_blank" rel="noreferrer"><img src={img18} width="45px" alt=""></img></a></Subcontainer2>  
         </TextContainer7>

<TextContainer>
<img src={img16} width="190px" alt=""></img><br></br>
Mint and staking Site under construction
  </TextContainer>
  
 

  <HalfContainer>
<img src={img6} width="270px" alt=""></img>
  </HalfContainer>
  
  <Inbet>Meme art and coins in loot box</Inbet>
   </Container>
 
   <Container>

   <HalfContainer3>

</HalfContainer3>
<Inbet>$LOOT is attractive</Inbet>
<TextContainer>
<img src={img13} width="190px" alt=""></img>
  </TextContainer>
  <Inbet>We are on Base</Inbet>

      

  <HalfContainer5>

  </HalfContainer5>

  <TextContainer>
CA: 0x00000TOBEANNOUNCED000000000000
  
  </TextContainer>
  <HalfContainer8>
</HalfContainer8>
<Inbet>Thanks for inspriration Pat </Inbet>
   </Container>
   <Container>
<Round></Round>

  <TextContainer>
  <img src={img17} width="190px" alt=""></img>
 <Subtext1> Chain: Base <br></br>
  Supply: 1 BILLION<br></br>
  Ticker: $LOOT<br></br>
  Tax: 0%<br></br>
</Subtext1>
  </TextContainer>
  <Round2><img src={img2} alt=""></img></Round2>
  <TextContainer>
  <img src={img12} width="250px" alt=""></img>
  <Subtext1>is a memecoin powered by Evil Twins Collective. MemeLoot is a meme coin without utility. At the most you will get some more coins or Art. Buy at own risk</Subtext1>
  
  </TextContainer>
 

   </Container>
      
     
  
     

</Section>
  )

}

export default Token